import { Component, OnInit, SecurityContext } from "@angular/core";
import { CommonService } from "../common.service";
import { AppStateService } from "../appStateService";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-follow-up-report",
  templateUrl: "./follow-up-report.component.html",
  styleUrls: ["./follow-up-report.component.scss"]
})
export class FollowUpReportComponent implements OnInit {
  submitted = false;
  selectedFile: File;
  resolutions: any = [];
  reportId: any;
  additionalInfo: any;
  mcrFollowUpDisclaimerText: string;
  isLoading: boolean;
  status: any;
  extensionMessage: boolean;
  isValidate = false;
  allowFileUpload: boolean;
  showMcrFollowUpDisclaimer: boolean = true;

  constructor(
    private commonService: CommonService,
    public appStateService: AppStateService,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private router: Router
  ) { }

  ngOnInit() {
    const crs = this.appStateService.reportId.split("-");
    crs.pop();
    this.reportId = crs.join("-");
    this.commonService.getResolutions(
      this.reportId,
      res => {
        this.resolutions = res;
        this.addFollowup(this.resolutions.length > 0 ? true : false);
        this.getStatus();
      },
      err => {
        this.showError(err);
      }
    );

    this.commonService.getMcrConfigDetailsByConfigId(
      this.appStateService.reportId.split("-")[1],
      this.appStateService.language,
      
      res => {
        this.allowFileUpload = this.appStateService.enviromentData.allowFilesForFollowUp
      ? res.hideFileUpload && this.appStateService.enviromentData.allowFilesForFollowUp
      : res.hideFileUpload && true;
      this.mcrFollowUpDisclaimerText = this.sanitizer.sanitize(SecurityContext.HTML, res.mcrFollowUpDisclaimer);
    
      if (!res.mcrFollowUpDisclaimer) {
      this.showMcrFollowUpDisclaimer = false;
    }
   
      },
      err => {
        this.toastr.error(err.message);
      }
    );

    // ensure selectedFiles array in common service is empty when making a new follow-up report
    this.commonService.selectedFiles = [];

    const uuid = this.commonService.generateGUID();
    sessionStorage.setItem('UUID', uuid.toString());
  }

  getStatus() {
    this.commonService.getStatus(
      this.appStateService.reportId.split("-")[1],
      this.reportId,
      res => {
        this.status = res;
      },
      err => {
        this.showError(err);
      }
    );
  }

  showError(err: any) {
    this.toastr.error(err.message);
  }

  addFollowup(hasResolutions: boolean) {
    this.commonService.addFollowup(
      this.appStateService.reportId.split("-")[1],
      this.appStateService.reportId,
      hasResolutions,

      res => {
        if (res) {
          this.appStateService.reportId = res;
        }
      },
      err => {
        this.showError(err);
        this.router.navigate(['/followUp'])
      }
    );
  }

  submitInfo() {
    if (this.appStateService.reportId != null && (this.appStateService.customerId == null || this.appStateService.customerId == undefined)) {
      this.appStateService.customerId = this.appStateService.reportId.split("-")[1];
    }
    this.isValidate = true;
    if (this.additionalInfo && this.additionalInfo.length > 0) {
      this.isLoading = true;
      this.commonService.updateFollowup(
        this.appStateService.reportId,
        this.additionalInfo,
        res => {
          if (res) {
            this.uploadFiles();
          }
        },
        err => {
          this.isLoading = false;
          this.showError(err);
        }
      );
    }
  }

  uploadFiles(): any {
    if (this.commonService.selectedFiles && this.commonService.selectedFiles.length > 0) {
      let filteredDocuments = this.commonService.selectedFiles.filter(doc => doc.isSuccess);
      if(filteredDocuments && filteredDocuments.length > 0){
        this.commonService.uploadDocuments(
          this.appStateService.reportId,
          this.commonService.selectedFiles,
          res => {
            if (res) {
              this.submitted = true;
              this.isLoading = false;
              this.status = "Pending";
              this.commonService.createAudit(
                this.appStateService.customerId,
                "FollowUp - Report Complete " + this.reportId,
                "/followUp-report"
              );
            }
          },
          err => {
            this.isLoading = false;
            this.showError(err);
          }
        );
      }
      else {
        this.submitted = true;
        this.isLoading = false;
        this.status = "Pending";
        this.commonService.createAudit(
          this.appStateService.customerId,
          "FollowUp - Report Complete " + this.reportId,
          "/followUp-report"
        );
      }
    } else {
      this.submitted = true;
      this.isLoading = false;
      this.status = "Pending";
      this.commonService.createAudit(
        this.appStateService.customerId,
        "FollowUp - Report Complete " + this.reportId,
        "/followUp-report"
      );
    }
  }

}
